.inputContainer {
  display: block;
  margin: 0;
  width: 100%;
}
.inputContainer label {
  display: block;
  font-size: 18px;
  padding-bottom: 5px;
  font-weight: bold;
}
.inputContainer input {
  display: block;
  width: 100%;
  outline: none;
  padding: 10px 15px;
  font-size: 24px;
  border: none;
  font-family: inherit;
  border: 1px solid #707070;
}
.errorMessage {
  color: var(--red-color);
  font-size: 14px;
}
.error input {
  border-color: var(--red-color);
}
.error label {
  color: var(--red-color);
}
.valueInputContainer {
  position: relative;
  display: inline-block;
  width: 100%;
  box-shadow: var(--blue-shadow-little);
  border-radius: 30px;
}
.valueInputContainer input {
  border-radius: 30px 0 0 30px;
  width: calc(100% - 70px);
  margin: 0;
  float: left;
  box-shadow: none;
}
.valueInputContainer input.percentage_input {
  background-color: rgba(49, 144, 203, 0.25);
  color: var(--green-dark-color);
  font-weight: bold;
}
.valueInputCurrency {
  width: 70px;
  float: left;
  color: var(--green-dark-color);
  background-color: rgba(49, 144, 203, 0.25);
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 41px;
  border-radius: 0 30px 30px 0;
  /* box-shadow: var(--blue-shadow-little); */
  font-weight: 500;
  font-size: 20px;
}
.phoneInput {
  position: relative;
}
.phoneInput input {
  padding-left: 100px;
  font-family: inherit;
}
.selectPrefixContainer {
  position: absolute;
  bottom: 0;
  padding: 7px 15px;
  border: none;
  box-shadow: none;
  font-weight: bold;
  color: var(--blue-grey-color);
  width: 100px;
}
.selectPrefixContainer {
  width: 100px;
  border: none;
}
.selectPrefixContainer .react-tel-input input.form-control {
  border: none;
  box-shadow: none;
  padding-left: 40px;
  font-weight: bold;
  color: var(--blue-grey-color);
}
.test {
  background-color: red;
}
.prefixContainer {
  top: 10px;
}
