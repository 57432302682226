.question {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 550px;
  margin: 20px auto;
  padding-bottom: 20px;
}
.question_label {
  /* min-height: 144px; */
  font-family: var(--font-red-hat-display);
  font-size: 30px;
  font-weight: bold;
  color: #202124;
  text-align: center;
  margin-bottom: 20px;
}
.question_sublabel {
  font-weight: normal;
}
.stepButton {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 40px;
  width: 100%;
}
.prevButton {
  border: 1px solid #0fb1b1;
  padding: 10px 30px;
  color: #0fb1b1;
  font-family: var(--font-red-hat-display);
  letter-spacing: 0.1px;
  box-shadow: 0px 3px 6px #00000029;
  background: transparent;
  margin-right: 18px;
  font-size: 25px;
  font-weight: bold;
  line-height: 33px;
}
.prevButton:hover {
  background: #0fb1b1;
  color: var(--grey-light-color);
}
.nextButton {
  background: #0a92de;
  border: 1px solid #0a92de;
  box-shadow: 0px 3px 6px #00000029;
  font-family: var(--font-red-hat-display);
  color: #fff;
  text-transform: uppercase;
  padding: 10px 0px;
  font-size: 25px;
  font-weight: bold;
  line-height: 33px;
  text-align: center;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 2 2 auto;
  -ms-flex: 2 2 auto;
  flex: 2 2 auto;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}
.nextButton:hover {
  color: #0a92de;
  background: #fff;
}
.lastButton {
  padding: 10px 0px;
  margin-bottom: 58px;
}
.suggestion {
  padding-top: 40px;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #757576;
}
.introsuggestion {
  padding-top: 10px;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #757576;
}
.percentageDisplay {
  color: #0E3B55;
  font-size: 20px;
  font-family: inherit;
  text-align: left;
  padding: 5px 0 0 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.disabledButton {
  background-color: #81919E;
  border-color: #81919E;
  cursor: not-allowed;
}
.disabledButton:hover {
  background-color: #81919E;
  border-color: #81919E;
  color: #fff;
}

/* .nextButtonCross {
} */
@media (max-width: 990px) {
  .question {
    padding-left: 26px;
    padding-right: 26px;
  }
  .question_label {
    font-size: 23px;
    padding-bottom: 20px;
  }
  .introsuggestion {
    padding-top: 0px;
  }
}
@media (max-width) {
  .nextButtonCross {
    display: none;
  }
  .nextButton {
    font-size: 15px;
  }
}
@media (max-height: 820px) {
  .question_label {
    padding-bottom: 30px;
  }
}
.exitButtonContainer {
  text-align: center;
}
.exitButton {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 30px;
  font-size: 18px;
  color: #000;
}
.leadSubmit {
  margin-bottom: 15px;
}
.requestSentSubLabel {
  margin-bottom: 32px;
  font-size: 23px;
  text-align: center;
}
.desktop {
  display: inline-block;
}
.mobile {
  display: none;
}
.question_label_align {
  text-align: left;
}
.containerSlide {
  margin: 0;
}
.containerSlideLatest {
  box-shadow: none !important;
  border: 0 !important;
  max-width: inherit !important;
}
.rowcontainerLead {
  padding: 19px 0px 59px;
}
.leftColLead {
  max-width: 50%;
  padding-top: 0;
  padding-left: 76px;
  padding-right: 76px;
}
.rightColLead {
  width: calc(100% - 607px);
  padding-left: 76px;
  padding-right: 76px;
}
.question_label_align {
  padding-bottom: 31px;
}
.exitButtonContainer p {
  font-size: 20px;
}
.greenButton {
  color: #fff;
  padding: 15px;
  border-radius: 0px;
  padding: 20px 5px;
  text-transform: uppercase;
  border: solid 1px var(--green-button);
  background-color: var(--green-button);
  transition: 0.5s;
  margin-bottom: 0;
  font-size: 18px;
  line-height: inherit;
}
.greenButton:hover {
  color: var(--green-button);
}
.greyButton {
  width: 100%;
  background: #808080;
  color: #fff;
  padding: 20px 5px;
  font-size: 12px;
  border-radius: 0px;
  text-transform: uppercase;
  border: solid 1px #808080;
  transition: 0.5s;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}
.greyButton:hover {
  background: #fff;
  color: #808080;
}
.leftColLead,
.rightColLead {
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}
.greenTitleContainer {
  width: 100%;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: var(--green-button);
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 30px;
}
.checkingPhoneText {
  font-weight: bold;
  font-size: 24px;
  max-width: 450px;
  width: 100%;
  margin: 0 auto 20px;
}
.checkingPhoneBlue {
  color: var(--blue-button);
}
.checkingPhoneGifContainer img {
  max-width: 400px;
  width: 100%;
  margin-bottom: 30px;
}
/* .errorPhoneContainer {
  background-color: var(--grey-light-color);
} */
.errorPhoneContainer label[for='phone'] {
  display: none;
}
.errorPhoneContainer .select-prefix-container {
  top: 5px;
}
.errorPhoneContainer .greenTitleContainer {
  margin-bottom: 0;
}
.errorPhoneContainer .leftColLead {
  border-right: none;
}
.errorImageContainer {
  text-align: center;
  margin: 30px auto 50px;
}
.errorImageContainer img {
  max-width: 100%;
  width: 400px;
}
.imageCheckDataDesktop {
  padding-left: 50px;
  padding-right: 50px;
}
.vericalButtons {
  flex-direction: column;
  min-height: 90px;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align:center;
  max-width: 550px;
  max-height: 110px;
  margin-top: 0;
  padding: 20px;
  font-size: 20px;
  margin-bottom: 20px;
}

.dataSafe {
  font-size: 20px;
  font-weight: bold;
  color: #00871a;
}

@media (min-width: 1597px) {
  .containerSlideLatest,
  .comtainerSlide .containerSlideLatest {
    width: 1500px !important;
    max-width: 1500px !important;
  }
}
@media (max-width: 1597px) {
  .containerSlideLatest {
    width: calc(100vw - 30px) !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .rowcontainerLead {
    padding: 10px 0px;
  }
  .leftColLead {
    max-width: 40%;
    /* min-width: ; */
    padding-top: 0;
    padding-left: 46px;
    padding-right: 46px;
  }
  .rightColLead {
    width: 60%;
    padding-left: 46px;
    padding-right: 46px;
    padding-top: 0px !important;
  }
  .exitButtonContainer p {
    font-size: 18px;
  }
  .greenButton {
    font-size: 15px;
  }
  .greyButton {
    font-size: 15px;
  }
}
@media (max-width: 1300px) {
  .imageCheckDataDesktop {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 990px) {
  .rowcontainerLead {
    padding: 0px;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: inline-block;
  }
  .question_label_align {
    text-align: center;
  }
  .mobileLeftCol {
    margin-top: 0;
    max-width: 600px;
    margin: 0 auto;
  }
  .containerSlide {
    margin-top: 50px;
    margin-left: -46px;
    margin-right: -46px;
  }
  .rightColLead {
    margin: 0 auto;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    max-width: 600px;
    padding-bottom: 10px !important;
  }
  .mobileLeftCol {
    padding: 0;
    text-align: left;
  }
  .mobileLeftCol p {
    text-align: center;
    margin-bottom: 0;
  }
  .question_sublabel {
    font-weight: 700;
    font-size: 20px;
    color: #103c55;
  }
}
@media (max-width: 770px) {
  .containerSlide {
    margin-top: 30px;
  }
}
@media (max-width: 600px) {
  .containerSlideLatest {
    margin: 30px 0 !important;
  }
  .containerSlide {
    margin-top: 10px;
  }
}
@media (max-width: 495px) {
  .containerSlideLatest {
    margin: 10px 0 !important;
  }
  .mobileLeftCol {
    padding: 0;
    text-align: left;
  }
  .mobileLeftCol p {
    text-align: center;
    margin-bottom: 0;
  }
  .rightColLead {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (max-width: 340px) {
  .greenButton {
    font-size: 13px;
  }
  .greyButton {
    font-size: 13px;
  }
}
@media (max-width: 320px) {
  .exitButtonContainer img {
    display: none;
  }
  .exitButtonContainer div.introDesc {
    text-decoration: underline;
  }
  .exitButtonContainer p,
  .exitButtonContainer div {
    padding-left: 0 !important;
    text-align: center;
  }
}
