.select {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
/* .answersErrorContainer {
 
} */
 .buttonsContainer {
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: column;
   -ms-flex-direction: column;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 20px;
 }

 .consultButtons {
   font-size: 23px;
   font-family: var(--font-red-hat-display);
   border-bottom: 1px solid;
 }

 .consultButtons.first {
   background: #0a92de;
   border: 1px solid #0a92de;
   box-shadow: 0px 3px 6px #00000029;
   font-family: var(--font-red-hat-display);
   color: #fff;
   padding: 10px;
   font-size: 23px;
   font-weight: bold;
   line-height: 33px;
   text-align: center;
 }

 .consultButtons.first:hover {
   color: #0a92de;
   background: #fff;
 }

 .info {
   font-size: 20px;
   font-weight: bold;
   color: #00871a;
 }
.answersContainer {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.button {
  border: 1px solid #707070;
  color: #000;
  font-family: var(--font-red-hat-display);
  font-size: 23px;
  text-align: center;
  padding: 10px 10px;
  min-width: 120px;
  margin-left: 10px;
  font-weight: normal;
}
.button.first {
  margin: 0;
}
.errorMessage {
  color: var(--red-color);
  font-size: 14px;
}
.button:hover,
.selected {
  background: #f0f0f0;
  color: #000;
  font-weight: bold;
}
@media (max-width: 991px) {
  /* .answersErrorContainer {
    min-height: 240px;
  } */
}
