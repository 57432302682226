/*.stepQuestionsContainer{
  padding-top: 115px;
  max-width: 950px;
  margin: 0 auto;
  background:#F4F3F4;
}*/
.stepQuestionsContainer {
  padding-top: 160px;
  background: #f4f3f4;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.headerContainer {
  text-align: center;
  color: #5c5c5c;
  background-color: #cac9c9;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 30px;
  padding: 12px 0;
}

.titleText h1 {
  background: #0A92DE;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 25px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  border-radius: 10px;
  width: 60%;
  margin: 0 auto;
}

.descriptionText {
  text-align: center;
  margin-top: -50px;
  margin-bottom: 50px;
  color: #757576;
  font-size: 25px;
}

@media (max-width: 991px) {
  .stepQuestionsContainer {
    padding-top: 75px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .headerContainer {
    margin: 27px 0 30px;
    padding: 12px 0;
  }
}

@media (max-width: 575px) {
  .titleText h1{
    width: calc(100% - 50px);
    padding: 0 5px;
    text-align: center;
    font-size: 14px;
    margin: 30px auto 0 auto;
  }
}

/* progressStepper.css */
.stepperContainer {
  width: 100%;
  max-width: 500px;
  margin: 10px auto 0 auto;
  padding: 20px 0;
}

.stepperWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.progressLine {
  position: absolute;
  left: 24px;
  right: 24px;
  top: 50%;
  height: 2.4px;
  background-color: #D9D9D9;
  max-width: 450px;
  width: 100%;
  transform: translateY(-600%);
}

.progressLineActive {
  position: absolute;
  left: 24px;
  right: 24px;
  top: 50%;
  height: 2.4px;
  max-width: 450px;
  background-color: #0C92DE;
  transform: translateY(-600%);
  transition: width 0.3s ease;
}

.step {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
}

.stepCircle {
  width: 24px;
  height: 24px;
  border: 2.4px solid #D9D9D9;
  border-radius: 50%;
  background-color: #FFFFFF;
  position: relative;
  z-index: 10;
}

.stepCircleActive {
  background-color: #0C92DE;
  border: 2.4px solid #0C92DE;
}

.stepLabel {
  margin-top: 0.5rem;
  font-size: 16px;
  color: #757576;
}

.stepLabelActive {
  color: #0E3B55;
  font-weight: bold;
}

@media (max-width: 991px) {
  .stepperContainer {
    width: 100%;
    max-width: 500px;
    margin: 50px auto 0 auto;
    padding: 20px 0;
  }
}

@media (max-width: 550px) {
  .stepperContainer {
    width: 80%;
    max-width: 500px;
    margin: 50px auto 0 auto;
    padding: 20px 0;
  }
  .progressLine {
    position: absolute;
    left: 24px;
    right: 24px;
    top: 50%;
    height: 2.4px;
    background-color: #D9D9D9;
    max-width: 450px;
    width: calc(100% - 48px);
    transform: translateY(-600%);
  }
  .progressLineActive {
    position: absolute;
    left: 24px;
    right: 24px;
    top: 50%;
    height: 2.4px;
    max-width: calc(100% - 50px);
    background-color: #0C92DE;
    transform: translateY(-600%);
    transition: width 0.3s ease;
  }
}

@media (max-width: 500px) {
  .stepperContainer {
    width: 80%;
    max-width: 500px;
    margin: 10px auto 0 auto;
    padding: 20px 0;
  }
}