.stepper {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.stepper.intro {
  padding-top: 30px;
  padding-bottom: 30px;

}
.stepperContainer {
  position: relative;
  width: 100%;
  background-color: #bababa;
  border-radius: 10px;
  max-width: 650px;
}
.stepperCompleted {
  position: relative;
  height: 10px;
  background: #0a92de;
  border-radius: 10px;
}
.percentage {
  padding-left: 10px;
  font-size: 20px;
  font-family: var(--font-red-hat-display);
  font-weight: bold;
  color: #0a92de;
}
@media (max-width: 992px) {
  .stepper {
    padding-top: 80px;
    padding-bottom: 10px;
    padding-left: 26px;
    padding-right: 26px;
  }
  .percentage {
    font-size: 20px;
  }
  .stepperCompleted {
    height: 10px;
  }
}
@media (max-height: 820px) {
  .stepper {
    padding-top: 60px;
    padding-bottom: 30px;
  }
}
@media (max-width: 450px) {
  .stepper {
    padding-top: 30px;
    padding-bottom: 10px;
  }
}
