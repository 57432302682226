.inputContainer {
  width: 100%;
  margin-bottom: 20px;
}
.select svg {
  fill: #707070;
}
.select span {
  display: none;
}
.inputContainer label {
  display: block;
  font-size: 18px;
  padding-bottom: 5px;
  font-weight: bold;
}
.inputContainer select {
  display: block;
  width: 100%;
  outline: none;
  font-size: 24px;
  border: none;
  border-radius: 0;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  font-family: inherit;
  color: var(--grey-color);
}
.errorMessage {
  color: var(--red-color);
  font-size: 14px;
}
.control {
  width: 100%;
  min-width: 260px;
  background: green;
}
@media (max-width: 991px) {
  .inputContainer {

  }
}
