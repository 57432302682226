.select {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
/* .answersErrorContainer {
  min-height: 240px;
} */
.answersContainer {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}
.button {
  border: 1px solid #707070;
  margin-bottom: 6px;
  color: #000;
  font-family: var(--font-red-hat-display);
  display: flex;
  align-items: center;
  font-size: 20px;
  text-align: left;
  padding: 5px 10px;
  min-width: 256px;
  width: 100%;
}
.errorMessage {
  color: var(--red-color);
  font-size: 14px;
}
.button:hover,
.selected {
  background: #f0f0f0;
  color: #000;
  font-weight: bold;
}
@media (max-width: 991px) {
  /* .answersErrorContainer {
    min-height: 240px;
  } */
}
