.inputContainer {
  display: block;
  padding: 0px;
  width: 100%;
  /* min-height: 100px; */
  
}
.inputContainer label {
  display: block;
  font-size: 18px;
  padding-bottom: 5px;
  font-weight: bold;
}
.inputContainer input {
  display: block;
  width: 100%;
  outline: none;
  padding: 10px 15px;
  font-size: 24px;
  border: none;
  font-family: inherit;
  border: 1px solid #707070;
}
.valueInputContainer {
  position: relative;
  display: inline-block;
  width: 100%;
  border: 1px solid #707070;
}
.valueInputContainer input {
  width: calc(100% - 70px);
  margin: 0;
  float: left;
  box-shadow: none;
  border: 0;
}
.errorMessage {
  color: var(--red-color);
  font-size: 14px;
}
.valueInputContainer input.percentage_input {
  background-color: rgba(49, 144, 203, 0.25);
  color: var(--green-dark-color);
  font-weight: bold;
}
.valueInputCurrency {
  width: 70px;
  float: left;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 49px;
  border-radius: 0 30px 30px 0;
  /* box-shadow: var(--blue-shadow-little); */
  font-weight: 500;
  font-size: 24px;
  font-family: inherit;
}
@media (max-width: 991px) {
  .inputContainer {
    /* min-height: 240px; */
  }
}
