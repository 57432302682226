.container {
  width: 100%;
  /* width: min(90%, 1024px);
    display: flex;
    justify-content: center;
    align-items: start;
    margin: 0 auto; */
}
.container .col {
  width: 100%;
  padding: 0 20px;
}
.container .col.leftCol {
  width: 40%;
}
.container .col.rightCol {
  width: 100%;
}
.suggestion {
  display: flex;
  background: #fff;
  padding: 20px;
}
.infoContainer {
  background: #fff;
  padding: 20px;
}
.suggestionImg {
  width: 100px;
  margin-right: 20px;
}
.switchContainer {
  display: flex;
  border: 1px solid #bcbcbc;
  border-radius: 8px;
  justify-content: space-between;
  padding: 4px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  max-width: 250px;
}
.switchContainer .switchButton {
  background: #fff;
  color: #0a92de;
  padding: 10px;
  width: 50%;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}
.switchContainer .switchButton.active {
  background: #0a92de;
  color: #fff;
  padding: 10px;
  width: 50%;
  text-align: center;
  border-radius: 4px;
}
.details {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}
.detailsContent {
  padding: 0 40px;
  text-align: center;
  width: max(50%, 150px);
}
.detailsContent.detailsR {
  border-right: 1px solid #0a92de;
}
.detailsContent .value {
  font-size: 40px;
  color: #0a92de;
  font-weight: bold;
}
.detailsContent .um {
  font-size: 20px;
  color: #0a92de;
  font-weight: bold;
}
.detailssustenibility {
  margin-bottom: 40px;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 8px;
}
.detailssustenibilityContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detailssustenibilityContent:first-child {
  margin-bottom: 15px;
}
.detailssustenibilityContent .value {
  width: 50%;
  text-align: center;
}
.detailssustenibilityContent .value .semaforo {
  color: #fff;
  background: #ccc;
  padding: 4px 20px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: bold;
}
.detailssustenibilityContent .value .semaforo.bassa {
  background: #ef1111;
}
.detailssustenibilityContent .value .semaforo.media {
  background: #ef6611;
}
.detailssustenibilityContent .value .semaforo.alta {
  background: #41ba58;
}
.nextStep button {
  background: #41ba58;
  border-color: #41ba58;
}
.nextStep button:hover {
  background: #fff;
  color: #41ba58;
  border-color: #41ba58;
}
.suggestionMobile {
  display: block;
  margin-bottom: 20px;
  padding-top: 0px;
}
.suggestionMobile .suggestion {
  padding-top: 0px;
  font-size: 18px;
}
.sustenibilityPerc {
  font-size: 30px;
  font-weight: bold;
}
.sustenibilityPerc.bassa {
  color: #ef1111;
}
.sustenibilityPerc.media {
  color: #ef6611;
}
.sustenibilityPerc.alta {
  color: #41ba58;
}
.um.bassa {
  color: #ef1111;
}
.um.media {
  color: #ef6611;
}
.um.alta {
  color: #41ba58;
}
@media (max-width: 1597px) {
  .detailsContent .value {
    font-size: 27px;
  }
  .infoContainer {
    padding: 0;
  }
  .container .col.rightCol {
    padding: 0px;
  }
}
@media (max-width: 991px) {
  .container {
    padding-top: 0px;
  }
  .infoContainer {
    padding: 40px 20px 10px 20px;
  }
  .container .col.rightCol {
    width: min(100%, 650px);
  }
  .leftCol {
    display: none;
  }
  .suggestionMobile {
    display: block;
  }
}
@media (max-width: 720px) {
  .detailsContent .value {
    font-size: 30px;
  }
}
@media (max-width: 600px) {
  .container {
    padding-top: 20px;
    width: 100%;
  }
  .container .col.rightCol {
    padding-left: 0;
    padding-right: 0;
  }
  .detailsContent {
    padding: 0 10px;
  }
}
@media (max-width: 500px) {
  .container {
    padding-top: 0px;
    width: 100%;
  }
}
@media (max-width: 450px) {
  .imgContainer {
    display: none;
  }
  .suggestionMobile .suggestion {
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
  }
}
@media (max-width: 400px) {
  .detailsContent .value {
    font-size: 20px;
  }
  .detailsContent.detailsR {
    padding-left: 0px;
  }
  .detailsContent.detailsL {
    padding-right: 0px;
  }
  .detailssustenibilityContent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .detailssustenibilityContent b {
    padding-bottom: 8px;
  }
  .detailssustenibilityContent:first-child {
    margin-bottom: 20px;
  }
}
