.inputContainer {
  display: block;
  padding: 5px 10px 10px 10px;
  width: 100%;
  /* min-height: 240px; */
}
.select svg {
  fill: #707070;
}
.select span {
  display: none;
}
.inputContainer label {
  display: block;
  font-size: 18px;
  padding-bottom: 5px;
  font-weight: bold;
}
.inputContainer input {
  display: block;
  width: 100%;
  outline: none;
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 24px;
  border: none;
}
.valueInputContainer {
  position: relative;
  display: inline-block;
  width: 100%;
  border-radius: 0px;
  border: 1px solid #707070;
}
.valueInputContainer input {
  border-radius: 0px;
  width: calc(100% - 70px);
  margin: 0;
  float: left;
  box-shadow: none;
}
.errorMessage {
  color: var(--red-color);
  font-size: 14px;
}
.valueInputContainer input.percentage_input {
  background-color: rgba(49, 144, 203, 0.25);
  color: var(--green-dark-color);
  font-weight: bold;
}
@media (max-width: 991px) {
  /* .inputContainer {
    min-height: 240px;
  } */
}
